import "./App.css";
import DemoCalendar from "./DemoCalendar";

function App() {
  return (
    <div className="App">
      <DemoCalendar />
    </div>
  );
}

export default App;

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useState } from "react";

const localizer = momentLocalizer(moment);

const tomorrowStart = new Date();
tomorrowStart.setDate(tomorrowStart.getDate() + 1);

const tomorrowEnd = new Date();
tomorrowEnd.setDate(tomorrowEnd.getDate() + 1);
tomorrowEnd.setHours(tomorrowEnd.getHours() + 4);

const DEFAULT_EVENTS = [
  {
    title: "Event 1",
    start: new Date(),
    end: new Date(new Date().setHours(new Date().getHours() + 1)),
  },
  {
    title: "Event 2",
    start: tomorrowStart,
    end: tomorrowEnd,
  },
];

console.log(DEFAULT_EVENTS);

const DnDCalendar = withDragAndDrop(Calendar);

const DemoCalendar = () => {
  const [events, setEvents] = useState(DEFAULT_EVENTS);

  const onEventResize = (data) => {
    console.log("[RESIZE]", data);

    const { start, end } = data;

    setEvents((currentEvents) => {
      return currentEvents.map((event) => {
        if (event.title === data.event.title) {
          return { ...event, start, end };
        }

        return event;
      });
    });
  };

  const onEventDrop = (data) => {
    console.log("[onEventDrop]", data);
    const { start, end } = data;

    setEvents((currentEvents) => {
      return currentEvents.map((event) => {
        if (event.title === data.event.title) {
          return { ...event, start, end };
        }

        return event;
      });
    });
  };

  return (
    <div className="demo-calendar-container">
      <DnDCalendar
        defaultView="week"
        localizer={localizer}
        events={events}
        startAccessor={"start"}
        endAccessor={"end"}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
        resizable
      />
    </div>
  );
};

export default DemoCalendar;
